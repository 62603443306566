import postData from "../assets/js/fetch.js";
import router from "../router";

let disparado = false;
let dev;
process.env.NODE_ENV === "production"
  ? (dev = "")
  : (dev = "https://xapp.icnea.net");

const compare = (a, b) => a.getTime() >= b.getTime();

const formatDate = (date) => {
  const allMonths = JSON.parse(sessionStorage.getItem("diccionario"))
    .monthShort;
  const allWeekdays = JSON.parse(sessionStorage.getItem("diccionario"))
    .weekdayShort;
  const months = [
    allMonths[0],
    allMonths[1],
    allMonths[2],
    allMonths[3],
    allMonths[4],
    allMonths[5],
    allMonths[6],
    allMonths[7],
    allMonths[8],
    allMonths[9],
    allMonths[10],
    allMonths[11],
  ];
  const week = [
    allWeekdays[0],
    allWeekdays[1],
    allWeekdays[2],
    allWeekdays[3],
    allWeekdays[4],
    allWeekdays[5],
    allWeekdays[6],
  ];

  let long_date = "";
  let day = date.slice(8, 10);
  let month = date.slice(5, 7);
  let year = date.slice(0, 4);
  let new_date = new Date(date);
  let day_week = new_date.getUTCDay();
  long_date =
    week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

  return long_date;
};

let dateNow = new Date().toISOString().slice(0, 10);

const state = {
  booking: {},
  instruccions: [],
  isNewMessage: null,
  smartKeys: "",
  stateDoor: false,
  itRegionBirth: [],
  itDocumentCity: [],
  bookingGuests: [],
  bookingPayments: [],
  bookingContract: [],
  newGuest: "0",
  xatConversa: [],
  guestDetails: [],
  terms: "",
  activitiesGroups: [],
  activities: "",
  activityDetail: [],
  distanceStore: 10,
  selectActivities: [],
  activityBooking: [],
  testimonis: [],
  shoppingCart: 0,
  minOneAdult: false,
  linksExternals: [],
  suplements: [],
  confirmAddSuplementsToCart: true,
};

const mutations = {
  setBooking(state, booking) {
    state.booking = booking;
  },
  setInstruccions(state, instruccions) {
    state.instruccions = instruccions;
  },
  setChatNewMessage(state, isNewMessage) {
    state.isNewMessage = isNewMessage;
  },
  setReservaPany(state, smartKeys) {
    state.smartKeys = smartKeys;
  },
  setStateDoor(state, stateDoor) {
    state.stateDoor = stateDoor;
  },
  setItRegionBirth(state, itRegionBirth) {
    state.itRegionBirth = itRegionBirth;
  },
  setItDocumentCity(state, itDocumentCity) {
    state.itDocumentCity = itDocumentCity;
  },
  setBookingGuests(state, bookingGuests) {
    state.bookingGuests = bookingGuests;
  },
  setNewGuest(state, newGuest) {
    ///////////////
    state.newGuest = newGuest;
  },
  setBookingPayments(state, bookingPayments) {
    state.bookingPayments = bookingPayments;
  },
  setBookingContract(state, bookingContract) {
    state.bookingContract = bookingContract;
  },
  setXatConversa(state, xatConversa) {
    state.xatConversa = xatConversa;
  },
  setGuestDetail(state, guestDetails) {
    state.guestDetails = guestDetails;
  },
  setTerms(state, terms) {
    state.terms = terms;
  },
  setActivitiesGroups(state, activitiesGroups) {
    state.activitiesGroups = activitiesGroups;
  },
  setActivities(state, activities) {
    state.activities = activities;
  },
  setActivityBooking(state, activityBooking) {
    state.activityBooking = activityBooking;
  },
  setActivityDetail(state, activityDetail) {
    state.activityDetail = activityDetail;
  },
  setDistance(state, distance) {
    state.distanceStore = distance;
  },
  setSelectActivitiesGroups(state, selectActivities) {
    state.selectActivities = selectActivities;
  },
  setTestimonis(state, testimonis) {
    state.testimonis = testimonis;
  },
  setShoppingCart(state, shoppingCart) {
    state.shoppingCart = shoppingCart;
  },
  setMinOneAdult(state, minOneAdult) {
    state.minOneAdult = minOneAdult;
  },
  setLinksExternals(state, linksExternals) {
    state.linksExternals = linksExternals;
  },
  setSuplements(state, suplements) {
    state.suplements = suplements;
  },
  setConfirmAddSuplementsToCart(state, confirmAddSuplementsToCart) {
    state.confirmAddSuplementsToCart = confirmAddSuplementsToCart;
  },
};

const actions = {
  // Guest
  getBookingID({ dispatch }, { usr }) {
    postData(dev + "/WebService.asmx/ReservaID", { usr }).then((data) => {
      console.log("ReservaID", data.d);
      const reserva = data.d;
      if (data.d === 0) {
        router.push({
          name: "Home",
          query: { id: router.history.current.query.id },
        });
      } else {
        dispatch("getBooking", { usr, reserva });
      }
    });
  },
  getBooking({ commit, dispatch }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/ReservaResum", {
      usr,
      reserva,
    }).then((data) => {
      if (data.d == "") {
        // Reserva error o caducada
        commit("setBooking", data.d);
      } else {
        let reservation = JSON.parse(String(data.d).replace("\\", "/"))
          .Response;

        if (reservation.status != "0") {
          reservation.guests =
            parseInt(reservation.adults) + parseInt(reservation.children);

          // Sumamos bebes si es formato portugués
          if (reservation.format_hostes == "PT") {
            reservation.sumAdultsChildren =
              parseInt(reservation.adults) +
              parseInt(reservation.children) +
              parseInt(reservation.babies);
            if (
              parseInt(reservation.registered_guests) ===
              parseInt(reservation.adults) +
                parseInt(reservation.children) +
                parseInt(reservation.babies)
            ) {
              reservation.guestsCompleted = true;
            } else {
              reservation.guestsCompleted = false;
            }
          } else {
            reservation.sumAdultsChildren =
              parseInt(reservation.adults) + parseInt(reservation.children);
            if (
              parseInt(reservation.registered_guests) ===
              parseInt(reservation.adults) + parseInt(reservation.children)
            ) {
              reservation.guestsCompleted = true;
            } else {
              reservation.guestsCompleted = false;
            }
          }

          if (
            parseInt(reservation.registered_guests) >=
            parseInt(reservation.adults)
          ) {
            reservation._registered_adults_only_completed = true;
          } else {
            reservation._registered_adults_only_completed = false;
          }

          if (parseFloat(reservation.pending_payment) <= 0) {
            //reservation.pending_payment_status = this.translator.pagat;
            reservation.paymentCompleted = true;
          } else {
            reservation.pending_payment_status =
              reservation.pending_payment + reservation.currency;
            reservation.paymentCompleted = false;
          }

          if (reservation.signed_contract === "true") {
            reservation.signed_contract_status = true; //this.translator.signat
            reservation.contractCompleted = true;
          } else {
            reservation.signed_contract_status = false; //this.translator.nosignat
            reservation.contractCompleted = false;
          }

          if (reservation.status === "6") {
            reservation.checkinCompleted = true;
          } else {
            reservation.checkinCompleted = false;
          }

          // Email booking y airbnb
          const email = reservation.customer_email;
          if (
            reservation.email_confirmation == "true" &&
            (email.endsWith("airbnb.com") || email.endsWith("booking.com"))
          ) {
            reservation.customer_email = "";
          }

          reservation.checkOutInTime = compare(
            new Date(dateNow),
            new Date(reservation.departure_date)
          );

          reservation._arrival_date_format = formatDate(
            reservation.arrival_date
          );
          reservation._departure_date_format = formatDate(
            reservation.departure_date
          );
          reservation._image_format = `background-image: url(${String(
            reservation.image
          )}); 
                                                    background-size: cover; 
                                                    background-repeat: no-repeat; 
                                                    height: 150px;
                                                    background-position: center;`;

          // Seteo de horas
          if (reservation.arrival_time != "") {
            String(reservation.arrival_time).length <= 2
              ? (reservation._arrival_time = `${reservation.arrival_time}:00`)
              : (reservation._arrival_time = `${reservation.arrival_time}`);
          } else {
            reservation._arrival_time = reservation.arrival_time;
          }

          if (reservation.departure_time != "") {
            String(reservation.departure_time).length <= 2
              ? (reservation._departure_time = `${reservation.departure_time}:00`)
              : (reservation._departure_time = `${reservation.departure_time}`);
          } else {
            reservation._departure_time = reservation.departure_time;
          }

          // Entrada desde
          parseInt(reservation.lodging_checkin_from) == 24
            ? (reservation._lodging_checkin_from_show_input = 0)
            : (reservation._lodging_checkin_from_show_input = parseInt(
                reservation.lodging_checkin_from
              ));
          // Entrada hasta
          parseInt(reservation.lodging_checkin_to) == 0
            ? (reservation._lodging_checkin_to_show_input = 24)
            : (reservation._lodging_checkin_to_show_input = parseInt(
                reservation.lodging_checkin_to
              ));

          // Si los campos de entrada del PMS estan vacios muestro todas las horas
          if (
            reservation._lodging_checkin_from_show_input == 0 &&
            reservation._lodging_checkin_to_show_input == 24
          ) {
            reservation._lodging_checkin_from_show_input = 16;
            reservation._lodging_checkin_to_show_input = 20;
          }

          // Salida desde
          parseInt(reservation.lodging_checkout_from) == 24
            ? (reservation._lodging_checkout_from_show_input = 0)
            : (reservation._lodging_checkout_from_show_input = parseInt(
                reservation.lodging_checkout_from
              ));
          // Salida hasta
          parseInt(reservation.lodging_checkout_to) == 0
            ? (reservation._lodging_checkout_to_show_input = 24)
            : (reservation._lodging_checkout_to_show_input = parseInt(
                reservation.lodging_checkout_to
              ));

          // Si los campos de salida del PMS estan vacios muestro todas las horas
          if (
            reservation._lodging_checkout_from_show_input == 0 &&
            reservation._lodging_checkout_to_show_input == 24
          ) {
            reservation._lodging_checkout_from_show_input = 9;
            reservation._lodging_checkout_to_show_input = 12;
          }

          // Cálculo de finalización de reserva
          const salida = new Date(reservation.departure_date);
          const salidaMas2Semanas = new Date(
            salida.setDate(salida.getDate() + 14)
          );
          new Date() < salidaMas2Semanas
            ? null
            : router.push({
                name: "GuestExpired",
                query: { id: router.history.current.query.id },
              });

          commit("setBooking", reservation);
          console.log("ReservaResum", reservation);

          // Si tiene llave
          if (reservation.smart_door_lock == "true") {
            dispatch("getReservaPany", {
              usr,
              reserva: reservation.reservation,
            });
          }
        } else {
          // si status a 0 reserva finalizada
          console.log("ReservaResum", reservation);
          commit("setBooking", reservation);
        }
      }
    });
  },
  postBookingSave(
    { dispatch },
    {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    }
  ) {
    postData(dev + "/WebService.asmx/ReservaGuardar", {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    }).then((data) => {
      console.log("ReservaGuardar", JSON.parse(data.d));
      if (JSON.parse(data.d) === true) {
        dispatch("getBooking", { usr, reserva: reservation });
      }
    });
  },
  getInstructions({ commit }, { usr }) {
    postData(dev + "/WebService.asmx/Instruccions", { usr }).then((data) => {
      console.log("Instruccions", JSON.parse(data.d).Response);
      commit("setInstruccions", JSON.parse(data.d).Response);
    });
  },
  getChatNewMessage({ commit }, { usr }) {
    postData(dev + "/WebService.asmx/XatNollegit", { usr }).then((data) => {
      console.log("XatNollegit", data.d);
      commit("setChatNewMessage", data.d);
    });
  },
  getReservaPany({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/ReservaPany", { usr, reserva }).then(
      (data) => {
        const limpiarData = data.d.replace(/\\/g, "/");
        console.log("ReservaPany", JSON.parse(limpiarData).Response);
        commit("setReservaPany", JSON.parse(limpiarData).Response);
      }
    );
  },
  openDoor({ commit }, { usr, reserva, pany, ip, vm, tObert }) {
    postData(dev + "/WebService.asmx/ObrirPorta", {
      usr,
      reserva,
      pany,
      ip,
    }).then((data) => {
      //console.log('ObrirPorta', data)
      if (data.d == true) {
        vm.$toast.success(tObert);
        commit("setStateDoor", true);
      } else {
        vm.$toast.error("Error");
        commit("setStateDoor", false);
      }
    });
  },
  resetOpenDoor({ commit }, { state }) {
    commit("setStateDoor", state);
  },

  //Guest_guests
  postAddGuest(
    { dispatch },
    {
      usr,
      reserva,
      hostId,
      nom,
      cognom1,
      cognom2,
      sexe,
      tipus,
      naixement_data,
      naixement_pais,
      naixement_lloc,
      naixement_regio,
      document_numero,
      document_tipus,
      document_data,
      document_pais,
      document_lloc,
      adreca_pais,
      adreca_lloc,
      adreca_dip,
    }
  ) {
    if (!disparado) {
      disparado = true;
      postData(dev + "/WebService.asmx/HostesGuardar", {
        usr,
        reserva,
        hostId,
        nom,
        cognom1,
        cognom2,
        sexe,
        tipus,
        naixement_data,
        naixement_pais,
        naixement_lloc,
        naixement_regio,
        document_numero,
        document_tipus,
        document_data,
        document_pais,
        document_lloc,
        adreca_pais,
        adreca_lloc,
        adreca_dip,
      }).then((data) => {
        console.log("HostesGuardar", JSON.parse(data.d));
        if (JSON.parse(data.d) === true) {
          dispatch("getBooking", { usr, reserva });
          dispatch("getHostes", { usr, reserva });
        }
      });

      setTimeout(() => {
        disparado = false;
      }, 1000);
    }
  },
  getHostesRegionsIT({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/HostesRegionsIT", { usr, reserva }).then(
      (data) => {
        console.log("HostesRegionsIT", JSON.parse(data.d).Response);
        commit("setItRegionBirth", JSON.parse(data.d).Response);
      }
    );
  },
  getHostesPoblesIT({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/HostesPoblesIT", { usr, reserva }).then(
      (data) => {
        console.log("HostesPoblesIT", JSON.parse(data.d).Response);
        commit("setItDocumentCity", JSON.parse(data.d).Response);
      }
    );
  },
  getHostes({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/Hostes", { usr, reserva }).then((data) => {
      // Comprobación de los campos obligatorios de cada huésped
      let people = JSON.parse(data.d).Response;

      people.forEach((guests) => {
        let isCorrect = true;

        let formatHostes = state.booking.format_hostes;

        guests._isAdult = isAdult(guests.guest_birthday_date);
        guests.guest_signature == ""
          ? (guests._isSign = false)
          : (guests._isSign = true);

        let isUploadPhotos = true;
        if (isAdult(guests.guest_birthday_date)) {
          // Comprobación de selfie + anverso DNI
          guests.guest_photo ? null : (isUploadPhotos = false);
          guests.document_photo == "" && guests.document_country == "ES"
            ? (isUploadPhotos = false)
            : null;
          guests.is_uploadAllPhotosAndSelfies = isUploadPhotos;

          //Comprobación de foto DNI
          guests.document_image
            ? (guests._isDocumentImage = true)
            : (guests._isDocumentImage = false);
          //Anverso
          guests.document_photo
            ? (guests._isFotoAnverso = true)
            : (guests._isFotoAnverso = false);
        } else {
          guests.is_uploadAllPhotosAndSelfies = isUploadPhotos;
        }

        let ciutadaItalia = false;

        for (const x in guests) {
          switch (formatHostes) {
            case "PT":
              x === "guest_first_name" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_last_name_one" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_sex" && guests[x] == "" ? (isCorrect = false) : "";
              x === "guest_sex" && guests[x] != "F" && guests[x] != "M"
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_type" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "address_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              break;
            case "IT":
              x === "guest_first_name" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_last_name_one" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_sex" && guests[x] == "" ? (isCorrect = false) : "";
              x === "guest_sex" && guests[x] != "F" && guests[x] != "M"
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_number" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_type" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_country" && guests[x] == "IT"
                ? (ciutadaItalia = true)
                : "";

              if (ciutadaItalia) {
                //x === "address_country" && guests[x] == '' ? isCorrect = false : '';
                x === "guest_birthday_region" && guests[x] == ""
                  ? (isCorrect = false)
                  : "";
                x === "guest_birthday_place" && guests[x] == ""
                  ? (isCorrect = false)
                  : "";
              }
              break;
            default:
              x === "guest_first_name" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_last_name_one" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "guest_sex" && guests[x] == "" ? (isCorrect = false) : "";
              x === "guest_sex" && guests[x] != "F" && guests[x] != "M"
                ? (isCorrect = false)
                : "";
              x === "guest_birthday_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_number" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_type" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_country" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              x === "document_date" && guests[x] == ""
                ? (isCorrect = false)
                : "";
              // if (isSpanish) {
              //     x === "guest_last_name_two" && guests[x] == '' ? isCorrect = false : '';
              // }
              break;
          }
        }

        isCorrect
          ? (guests._input_empty = false)
          : (guests._input_empty = true);
      });

      function isAdult(fecha) {
        let hoy = new Date();
        let birth = new Date(fecha);
        let edad = hoy.getFullYear() - birth.getFullYear();
        let diferenciaMeses = hoy.getMonth() - birth.getMonth();
        if (
          diferenciaMeses < 0 ||
          (diferenciaMeses === 0 && hoy.getDate() < birth.getDate())
        ) {
          edad--;
        }
        let isAdult = false;

        if (edad >= 16) {
          isAdult = true;
          commit("setMinOneAdult", true);
        }

        return isAdult;
      }

      commit("setBookingGuests", people);
      console.log("Hostes", people);
    });
  },
  deleteHostesEliminar({ dispatch }, { usr, reserva, hostId }) {
    postData(dev + "/WebService.asmx/HostesEliminar", {
      usr,
      reserva,
      hostId,
    }).then((data) => {
      if (data.d === "True") {
        dispatch("getBooking", { usr, reserva });
        dispatch("getHostes", { usr, reserva });
      }
    });
  },
  postSignGuest({ dispatch }, { usr, reserva, hostId, base64 }) {
    postData(dev + "/WebService.asmx/HostesSignatura", {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      console.log("HostesSignatura", data.d);
      if (data.d == true) {
        dispatch("getBooking", { usr, reserva });
        dispatch("getHostes", { usr, reserva });
      }
    });
  },
  // Hostes detall
  getHostesDetall({ commit }, { usr, reserva, id }) {
    postData(dev + "/WebService.asmx/HostesDetall", { usr, reserva, id }).then(
      (data) => {
        console.log("HostesDetall", JSON.parse(data.d).Response);
        commit("setGuestDetail", JSON.parse(data.d).Response);
      }
    );
  },
  // Lectura DNI
  postPhotoDNI({ dispatch, commit }, { usr, reserva, hostId, base64 }) {
    postData(dev + "/WebService.asmx/HostesPassaport", {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      commit("setNewGuest", data.d);
      dispatch("getBooking", { usr, reserva });
      dispatch("getHostes", { usr, reserva });
    });
  },

  // Foto anverso y reverso DNI posición 1 = reverso 2 = anverso
  postSavePhotoFrontPassport(
    { dispatch },
    { usr, reserva, hostId, posicio, base64 }
  ) {
    postData(dev + "/WebService.asmx/HostesPassaportFoto", {
      usr,
      reserva,
      hostId,
      posicio,
      base64,
    }).then((data) => {
      data.d == true ? dispatch("getHostes", { usr, reserva }) : "";
    });
  },
  // Foto selfie
  postSaveSelfie({ dispatch }, { usr, reserva, hostId, base64 }) {
    postData(dev + "/WebService.asmx/HostesFoto", {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      console.log("HostesFoto", data.d);
      data.d == true ? dispatch("getHostes", { usr, reserva }) : "";
    });
  },

  // Guest_payment
  getBookingPayments({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/ReservaDetall", { usr, reserva }).then(
      (data) => {
        console.log("ReservaDetall", JSON.parse(data.d).Response);
        commit("setBookingPayments", JSON.parse(data.d).Response);
      }
    );
  },
  postCardPayment({ dispatch }, { usr, reserva, cobrar, diposit, paypal }) {
    console.log("pasa");
    postData(dev + "/WebService.asmx/Banc", {
      usr,
      reserva,
      cobrar,
      diposit,
      paypal,
    }).then((data) => {
      console.log("Banc", data);
      if (data.d != "") {
        dispatch("getBookingPayments", { usr, reserva });
        window.location.href = data.d;
      }
    });
  },
  postCashPayment({ dispatch }, { usr, reserva, importPagar }) {
    postData(dev + "/WebService.asmx/ReservaPagament", {
      usr,
      reserva,
      import: importPagar,
    }).then((data) => {
      console.log("ReservaPagament", data.d);
      dispatch("getBookingPayments", { usr, reserva });
    });
  },

  // Guest_contract
  getBookingContract({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/Contracte", { usr, reserva })
      .then((data) => {
        if (data.d != "") {
          const json = data.d;
          console.log(
            "Contracte",
            JSON.parse(json.replace(/\\/g, "/")).Response
          );
          commit(
            "setBookingContract",
            JSON.parse(json.replace(/\\/g, "/")).Response
          );
        } else {
          commit("setBookingContract", "");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  postContracteSignatura({ dispatch }, { usr, reserva, jpg, ip, language }) {
    postData(dev + "/WebService.asmx/ContracteSignatura", {
      usr,
      reserva,
      jpg,
      ip,
    }).then((data) => {
      console.log("Contracte", JSON.parse(data.d).Response);
      dispatch("getBookingContract", { usr, reserva, language });
    });
  },

  // Guest_chat
  getChat({ commit }, { usr }) {
    postData(dev + "/WebService.asmx/XatConversa", { usr }).then((data) => {
      console.log("XatConversa", data);
      data.d == ""
        ? commit("setXatConversa", "")
        : commit("setXatConversa", JSON.parse(data.d).Response);
    });
  },
  postSendMessage({ dispatch }, { usr, text, ip }) {
    postData(dev + "/WebService.asmx/XatMissatge", { usr, text, ip }).then(
      (data) => {
        console.log("XatMissatge", data);
        dispatch("getChat", { usr });
      }
    );
  },

  // Guest_terms
  getTerms({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/CondicionsLegals", { usr, reserva }).then(
      (data) => {
        if (data.d != "") {
          console.log("CondicionsLegals", data.d);
          commit("setTerms", data.d);
        }
      }
    );
  },

  // Guest_Activities
  getActivitiesGroups({ commit }, { usr, reserva, distancia }) {
    postData(dev + "/WebService.asmx/ActivitatsGrups", {
      usr,
      reserva,
      distancia,
    }).then((data) => {
      if (data.d != "") {
        console.log(
          "ActivitatsGrups",
          JSON.parse(data.d).Response.activities_groups
        );
        commit(
          "setActivitiesGroups",
          JSON.parse(data.d).Response.activities_groups
        );
      } else {
        console.log("ActivitatsGrupsError", data);
        commit("setActivitiesGroups", []);
      }
    });
  },
  getActivities({ commit }, { usr, reserva, distancia, grups }) {
    //commit('setActivities', []);
    postData(dev + "/WebService.asmx/Activitats", {
      usr,
      reserva,
      distancia,
      grups,
    })
      .then((data) => {
        console.log("ActivitatsRaw", data);
        console.log("Activitats", JSON.parse(data.d).Response.activities);
        commit("setActivities", JSON.parse(data.d).Response.activities);
      })
      .catch((err) => console.error(err));
  },

  getActivityDetail({ commit }, { usr, reserva, id, datefrom, dateto }) {
    postData(dev + "/WebService.asmx/ActivitatDetall", {
      usr,
      reserva,
      id,
      datefrom,
      dateto,
    }).then((data) => {
      //console.log('ActivitatDetall', data.d)
      console.log("ActivitatDetall", JSON.parse(data.d).Response);

      commit("setActivityDetail", JSON.parse(data.d).Response);
      //commit('setActivityDetail', data.d);
    });
  },

  changeDistance({ commit }, { distance }) {
    commit("setDistance", parseInt(distance));
  },
  saveSelectActivitiesGroups({ commit }, { selectActivities }) {
    commit("setSelectActivitiesGroups", selectActivities);
  },

  getActivitatReservaCrear({ dispatch }, { usr, reserva, activities }) {
    console.log(reserva);
    postData(dev + "/WebService.asmx/ActivitatReservaCrear", {
      usr,
      reserva,
    }).then((data) => {
      console.log("ActivitatReservaCrear", data.d);
      dispatch("postActivitatReservaAfegir", {
        usr,
        reserva: data.d,
        activities,
      });
    });
  },
  postActivitatReservaAfegir(context, { usr, reserva, activities }) {
    const delay = (t) => new Promise((r) => setTimeout(r, t));
    if (activities.length > 0) {
      activities.forEach(async (activity, i) => {
        console.log("postActivitatReservaAfegir", activity, i);

        const activitat = +String(activity.activitat).replace("I", ""); ////// ¿?¿?
        const dataactivitat = activity.data;
        const session = parseInt(activity.session);

        if (Object.entries(activity.tarifa).length !== 0) {
          for (const [tar, per] of Object.entries(activity.tarifa)) {
            const tarifa = parseInt(tar);
            const suplement = 0;
            const persones = parseInt(per);
            // Adding row of the rate

            //console.log(usr, reserva, dataactivitat, activitat, suplement, tarifa, session, persones)
            postData(dev + "/WebService.asmx/ActivitatReservaAfegir", {
              usr,
              reserva,
              dataactivitat,
              activitat,
              suplement,
              tarifa,
              session,
              persones,
            }).then((data) => {
              console.log("ActivitatReservaAfegir", data);
            });

            await delay(300);
          }

          if (Object.entries(activity.suplement) !== 0) {
            for (const [tar, per] of Object.entries(activity.suplement)) {
              ////// Faltaria pasar el nombre de la tarifa para campo amount_rate("units") ////
              const tarifa = parseInt(tar.split("$")[0]);
              const suplement = parseInt(tar.split("$")[1]);
              const persones = parseInt(per);

              // Adding row of the rate
              postData(dev + "/WebService.asmx/ActivitatReservaAfegir", {
                usr,
                reserva,
                dataactivitat,
                activitat,
                suplement,
                tarifa,
                session,
                persones,
              }).then((data) => {
                console.log("ActivitatReservaAfegir", data);
              });
              await delay(300);
            }
          }
        }
      });
      setTimeout(() => {
        reserva != 0
          ? router.push({
              name: "GuestReservation",
              params: { reserva: reserva },
              query: { id: router.history.current.query.id },
            })
          : (this.error = true);
      }, 2000);
    }
  },
  addShoppingCart({ commit }, { numActivities }) {
    commit("setShoppingCart", numActivities);
  },

  // Guest_Reviews
  getTestimoni({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/Testimoni", { usr, reserva }).then(
      (data) => {
        if (data.d != "") {
          console.log("TestimoniRaw", data);
          console.log("Testimoni", JSON.parse(data.d).Response);
          commit("setTestimonis", JSON.parse(data.d).Response);
        }
      }
    );
  },
  postTestimoni(
    { dispatch },
    {
      usr,
      reserva,
      preg1,
      preg2,
      preg3,
      preg4,
      preg5,
      titular,
      text_positiu,
      text_negatiu,
      vm,
      msn,
    }
  ) {
    postData(dev + "/WebService.asmx/TestimoniGuardar", {
      usr,
      reserva,
      preg1,
      preg2,
      preg3,
      preg4,
      preg5,
      titular,
      text_positiu,
      text_negatiu,
    }).then((data) => {
      console.log("TestimoniGuardar", data);
      if (data.d) {
        vm.$toast.success(msn);
        dispatch("getTestimoni", { usr, reserva });
      }
    });
  },
  getLinksExternals({ commit }, { usr }) {
    postData(dev + "/WebService.asmx/Links", { usr }).then((data) => {
      console.log("Links", JSON.parse(data.d).Response);
      commit("setLinksExternals", JSON.parse(data.d).Response);
    });
  },

  getSuplements({ commit }, { usr, reserva }) {
    postData(dev + "/WebService.asmx/Suplements", { usr, reserva }).then(
      (data) => {
        console.log("Suplements", JSON.parse(data.d).Response.supplements);
        commit("setSuplements", [JSON.parse(data.d).Response.supplements]);
      }
    );
  },
  postSuplementsAfegir({ commit }, { usr, reserva, suplementID, pax }) {
    return new Promise((resolve, reject) => {
      postData(dev + "/WebService.asmx/SuplementsAfegir", {
        usr,
        reserva,
        suplementID,
        pax,
      })
        .then((data) => {
          console.log("SuplementsAfegir", data);
          if (data.d === false) {
            commit("setConfirmAddSuplementsToCart", false);
          }
          resolve(data);
        })
        .catch((error) => {
          console.error("Error en SuplementsAfegir:", error);
          commit("setConfirmAddSuplementsToCart", false);
          reject(error);
        });
    });
  },
  postSuplementsAnular({ dispatch }, { usr, reserva, suplementID }) {
    return new Promise((resolve, reject) => {
      postData(dev + "/WebService.asmx/SuplementsAnular", {
        usr,
        reserva,
        suplementID,
      })
        .then((data) => {
          console.log("SuplementsAnularRaw", data.d);
          resolve(dispatch("getSuplements", { usr, reserva }));
        })
        .catch((error) => {
          console.error("Error en SuplementsAnular:", error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
