import postData from '../assets/js/fetch.js';

let dev;
process.env.NODE_ENV === 'production' ? dev = "" : dev = "https://xapp.icnea.net";

const compare = (a, b) => a.getTime() >= b.getTime();

const formatDate = (date) => {
    const allMonths = JSON.parse(sessionStorage.getItem('diccionario')).monthNames;
    const allWeekdays = JSON.parse(sessionStorage.getItem('diccionario')).weekdayShort;
    const months = [allMonths[0], allMonths[1], allMonths[2], allMonths[3], allMonths[4], allMonths[5], allMonths[6], allMonths[7], allMonths[8], allMonths[9], allMonths[10], allMonths[11]];
    const week = [allWeekdays[0], allWeekdays[1], allWeekdays[2], allWeekdays[3], allWeekdays[4], allWeekdays[5], allWeekdays[6]];
    
    let long_date = '';
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    let new_date = new Date(date);
    let day_week = new_date.getUTCDay();
    long_date = week[day_week] + ', ' + day + ' ' + months[month - 1] + ' ' + year;
    
    return long_date;
};

let dateNow = new Date().toISOString().slice(0, 10);

const state = {
    services: [],
    serviceDetail: [],
    calendar: [],
    booking: {},
};

const mutations = {
    setServices(state, services) {
        state.services = services;
    },
    setServiceDetail(state, serviceDetail) {
        state.serviceDetail = serviceDetail;
    },
    setCalendar(state, calendar) {
        state.calendar = calendar;
    },
    setBooking(state, booking) {
        state.booking = booking;
    },
};

const actions = {
    //// Service ////
    getService({ commit }, { usr }) {
        postData(dev + "/WebService.asmx/Servei", { usr })
        .then((data) => {
            console.log('Servei', JSON.parse(data.d).Response);
            commit("setServices", JSON.parse(data.d).Response);
        });
    },
    
    //// ServiceDetail ////
    getServiceDetail({ commit }, { usr, id }) {
        postData(dev + "/WebService.asmx/ServeiDetall", { usr, id })
        .then((data) => {
            console.log('ServeiDetall', JSON.parse(data.d).Response);
            commit("setServiceDetail", JSON.parse(data.d).Response);
        });
    },
    postServeiEnviarFotos({ dispatch }, { usr, id, image4, image5, image6 }) {
        const image1 = '', image2 = '', image3 = '';
        postData(dev + "/WebService.asmx/ServeiImatge", { usr, id, image1, image2, image3, image4, image5, image6 })
        .then((data) => {
            console.log('ServeiImatge', data);
            dispatch("getServiceDetail", { usr, id });
        });
    },
    postServeiGuardarInforme({ dispatch }, { usr, id, informe, preu }) {
        postData(dev + "/WebService.asmx/ServeiGuardar", { usr, id, informe, import: preu })
        .then((data) => {
            console.log('ServeiGuardar', data.d);
            data.d == true ? dispatch("getServiceDetail", { usr, id }) : '';  
        });
    },
    postEndService({ dispatch }, { usr, id }) {
        postData(dev + "/WebService.asmx/ServeiFinal", { usr, id })
        .then((data) => {
            console.log('ServeiFinal', data.d);
            data.d == true ? dispatch("getServiceDetail", { usr, id }) : '';  
        });
    },

    //// ServiceCalendar ////
    getCalendar({ commit }, { usr, data }) {
        postData(dev + "/WebService.asmx/Calendari", { usr, data })
        .then((data) => {
            console.log(JSON.parse(data.d).Response)
            commit("setCalendar", JSON.parse(data.d).Response);
        });
    },
    //// ServiceCalendarInfo ////
    getBooking({ commit }, { usr, reserva }) {
        postData(dev + "/WebService.asmx/ReservaResum", { usr, reserva })
        .then((data) => {
            console.log('ReservaResum', JSON.parse(data.d).Response);
            let reservation = JSON.parse(data.d).Response;

            reservation.checkOutInTime = compare(new Date(dateNow), new Date(reservation.departure_date));
            reservation.sumAdultsChildren = parseInt(reservation.adults) + parseInt(reservation.children);

            reservation._arrival_date_format = formatDate(reservation.arrival_date);
            reservation._departure_date_format = formatDate(reservation.departure_date);

            commit("setBooking", reservation);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};